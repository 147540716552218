import { InputLabel } from "./InputLabel";

type GroupedCheckboxesProps = {
  options: string[];
  label: string;
  selectedOptions: string[];
  onCheck: (value: string[]) => void;
};

export const GroupedCheckboxes = ({
  options,
  label,
  selectedOptions,
  onCheck,
}: GroupedCheckboxesProps) => (
  <div>
    <InputLabel>{label}</InputLabel>
    {options.map((option) => (
      <div key={option} className="flex items-center gap-2">
        <input
          type="checkbox"
          onChange={() => {
            if (selectedOptions.includes(option))
              selectedOptions = selectedOptions.filter(
                (item) => item !== option,
              );
            else selectedOptions.push(option);
            onCheck(selectedOptions);
          }}
          id={option}
          value={option}
          className="rounded"
        />
        <label>{option}</label>
      </div>
    ))}
  </div>
);
