import useSWR from "swr";
import { getAPI } from "../api";
import { OpportunityWithoutId, Opportunity } from "../models/opportunity";

const opportunitiesKey = "opportunities";

const opportunitiesFetcher = async ([_, mechanicId]: [
  key: string,
  mechanicId?: string,
]) => {
  const api = await getAPI();
  return api.getOpportunities({ mechanicId });
};

export const addOpportunity = async (opportunity: OpportunityWithoutId) => {
  const api = await getAPI();
  await api.addOpportunity({
    opportunity,
  });
};

export const updateOpportunity = async (opportunity: Opportunity) => {
  const api = await getAPI();
  await api.updateOpportunity(opportunity);
};

export const useOpportunities = (mechanicId?: string) => {
  const {
    data: opportunities,
    isLoading,
    mutate,
  } = useSWR([opportunitiesKey, mechanicId], opportunitiesFetcher);

  return {
    opportunities,
    isLoadingOpportunities: isLoading,
    addOpportunity,
    reloadOpportunities: mutate,
    updateOpportunity: async (opportunity: Opportunity) => {
      await updateOpportunity(opportunity);
      await mutate();
    },
  };
};
