import { Coords } from "../location";
import { z } from "zod";

export const CopilotProperty = z.object({
  id: z.string(),
  name: z.string(),
  address: z.string().min(1, "Address is required"),
  location: Coords,
  workingHours: z.string(),
});

export const CopilotPropertyWithoutId = CopilotProperty.omit({ id: true });

export type CopilotProperty = z.infer<typeof CopilotProperty>;
export type CopilotPropertyWithoutId = z.infer<typeof CopilotPropertyWithoutId>;
