import { z } from "zod";
export const Coords = z.object({
  lat: z
    .number()
    .min(-90, "Latitude must be between -90 and 90 degrees")
    .max(90, "Latitude must be between -90 and 90 degrees"),
  lng: z
    .number()
    .min(-180, "Longitude must be between -180 and 180 degrees")
    .max(180, "Longitude must be between -180 and 180 degrees"),
});
export type Coords = z.infer<typeof Coords>;
