import z from "zod";

import { Elevator } from "./elevator";
import { coerceUndefinedToNull } from "./utils";
import {
  opportunitySchemaWithoutId,
  recommendationTypeOptions,
} from "./opportunity";

export const recommendationFormSchema = z.object({
  type: z.enum(recommendationTypeOptions),
  recommendationOptions: z.array(z.string()).optional(),
  opportunity: z.string().optional().default(""),
});

export type RecommendationForm = z.infer<typeof recommendationFormSchema>;

export const SurveyVm = Elevator.omit({
  machineInstallYear: true,
  controllerInstallYear: true,
  suspensionMeansInstallYear: true,
}).extend({
  machineInstallYear: coerceUndefinedToNull(z.coerce.string()),
  controllerInstallYear: coerceUndefinedToNull(z.coerce.string()),
  suspensionMeansInstallYear: coerceUndefinedToNull(z.coerce.string()),
  recommendation: opportunitySchemaWithoutId.nullable().default(null),
});
export type SurveyVm = z.infer<typeof SurveyVm>;
