import z from "zod";
import {
  HydroSystemType,
  SystemType,
  SystemTypeOption,
  TractionSystemType,
} from "../api/interface";
import { coerceUndefinedToNull } from "./utils";

export const yesNoOption = { id: true, label: "Yes" };
export const noOption = { id: false, label: "No" };
export const yesNoOptions = [yesNoOption, noOption] as const;
export type YesNoOption = (typeof yesNoOptions)[number];

export const yesNoOptionToBool = (value: YesNoOption): boolean => {
  return value.id;
};

export const isTraction = (systemType: SystemTypeOption): boolean => {
  return (Object.values(TractionSystemType) as SystemTypeOption[]).includes(
    systemType,
  );
};

export const isHydraulic = (systemType: SystemTypeOption): boolean => {
  return (Object.values(HydroSystemType) as SystemTypeOption[]).includes(
    systemType,
  );
};

export const conditionOptions = ["good", "fair", "poor"] as const;
export type ConditionOption = (typeof conditionOptions)[number];

export const conditionValueFormatter = (value: string | null): string => {
  switch (value) {
    case "good":
      return "Good";
    case "fair":
      return "Fair";
    case "poor":
      return "Poor";
  }

  throw new Error(`Invalid radio option: ${value}`);
};

export const trafficOptions = ["high", "medium", "low"] as const;

export const trafficValueFormatter = (value: string | null): string => {
  switch (value) {
    case "high":
      return "High";
    case "medium":
      return "Medium";
    case "low":
      return "Low";
  }

  throw new Error(`Invalid traffic option: ${value}`);
};

// TODO: Possibly find a better place for this
export const yearOptions = ((): string[] => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) =>
    (1950 + i).toString(),
  ).reverse();
  years.push("Pre 1950");

  return years;
})();

export const systemTypeValues = Object.values(SystemType);

export const systemTypesOrder = new Map([
  [SystemType.HYDRO_DIRECT, 0],
  [SystemType.TRACTION_GEARED, 1],
  [SystemType.TELESCOPIC, 2],
  [SystemType.ROPED_HYDRO, 3],
  [SystemType.GEARLESS, 4],
  [SystemType.ESCALATOR, 5],
  [SystemType.MOVING_WALK, 6],
  [SystemType.DUMBWAITER_TRACTION, 7],
  [SystemType.DUMBWAITER_HYDRO, 8],
  [SystemType.VPL_TRACTION, 9],
  [SystemType.VPL_HYDRO, 10],
  [SystemType.WHEELCHAIR_LIFT, 11],
  [SystemType.HANDICAP_LIFT_HYDRO, 12],
  [SystemType.HANDICAP_LIFT_TRACTION, 13],
  [SystemType.TWIN_POST_HOLE_LESS, 14],
]);

export const fireServiceOptions = [
  "phase-1-only",
  "phase-1-and-2",
  "none",
] as const;

export const fireServiceValueFormatter = (value: string | null): string => {
  switch (value) {
    case "phase-1-only":
      return "Phase I (only)";
    case "phase-1-and-2":
      return "Phase I & II";
    case "none":
      return "None";
  }

  throw new Error(`Invalid fire service value: ${value}`);
};
export const environmentOptions = [
  "clean",
  "grimy",
  "dusty",
  "filthy",
  "salty",
] as const;

export const environmentValueFormatter = (value: string | null): string => {
  switch (value) {
    case "clean":
      return "Clean";
    case "grimy":
      return "Grimy";
    case "dusty":
      return "Dusty";
    case "filthy":
      return "Filthy";
    case "salty":
      return "Salty";
  }

  throw new Error(`Invalid environment value: ${value}`);
};

export const guideOptions = ["Slide Guide", "Roller Guide"] as const;

export const suspensionOptions = [
  "Wire Rope",
  "Flat Belt",
  "Coated Rope",
] as const;

export const motorControllerOptions = [
  "Across The Line Starter",
  "WYE - Delta Starter",
  "Solid State Starter",
  "Single Speed AC",
  "Two Speed AC",
  "MG (Motor Generator)",
  "SCR (Silicone Controlled Rectifier)",
  "VVVF (Variable Voltage Variable Frequency)",
] as const;

export const upgradeNeededOpportunities: (keyof Elevator)[] = [
  "doorEquipmentUpgradeNeeded",
  "hallFixturesUpgradeNeeded",
  "positionIndicatorUpgradeNeeded",
  "pumpUnitUpgradeNeeded",
  "controllerUpgradeNeeded",
  "machineUpgradeNeeded",
  "cabUpgradeNeeded",
] as const;

export const existOpportunities: (keyof Elevator)[] = [
  "enforcerDoorGibs",
  "LEDDownlightCeiling",
  "softStartElectronicStarter",
  "tankCooler",
  "tankHeater",
  "ropeGripper",
  "carTopHandrails",
  "singleBottomBulkheadJack",
  "retractablePitLadder",
  "retractableToeGuard",
  "scavengerPump",
  "threeDSensorEdge",
  "DLM",
  "biDirectionalGovernor",
] as const;

export const tractionHydroDifferences: (keyof Elevator)[] = [
  "ropeGripper",
  "softStartElectronicStarter",
  "tankCooler",
  "tankHeater",
  "biDirectionalGovernor",
  "machineUpgradeNeeded",
  "pressureSwitchHydro",
  "flexHoseHydro",
  "pumpUnitUpgradeNeeded",
  "plungerGripperHydro",
  "overspeedValveHydro",
  "singleBottomBulkheadJack",
] as const;

export const imageOptions = z
  .object({
    url: z.string(),
    id: coerceUndefinedToNull(z.number()),
  })
  .or(z.string()); // TODO: Remove when legacy data is migrated

export const Elevator = z.object({
  id: z.string(),
  propertyId: z.string(),
  servmanId: coerceUndefinedToNull(z.string()),
  propertyName: coerceUndefinedToNull(z.string()),
  propertyAddress: coerceUndefinedToNull(z.string()),
  name: coerceUndefinedToNull(z.string()),
  unitNumber: coerceUndefinedToNull(z.string()),
  ahj: coerceUndefinedToNull(z.string()),
  typeOfUse: coerceUndefinedToNull(z.string()),
  ahjId: coerceUndefinedToNull(z.string()),
  fireService: coerceUndefinedToNull(z.enum(fireServiceOptions)),
  facilityType: coerceUndefinedToNull(z.string()),
  traffic: coerceUndefinedToNull(z.enum(trafficOptions)),
  doorType: coerceUndefinedToNull(z.string()),
  condition: coerceUndefinedToNull(z.enum(conditionOptions)),
  designQuality: coerceUndefinedToNull(z.enum(conditionOptions)),
  environment: coerceUndefinedToNull(z.enum(environmentOptions)),
  technology: coerceUndefinedToNull(z.string()),
  machineInstallYear: coerceUndefinedToNull(z.coerce.number().int()),
  controllerInstallYear: coerceUndefinedToNull(z.coerce.number().int()),
  landings: coerceUndefinedToNull(z.coerce.number().int().gt(0)),
  frontLandings: coerceUndefinedToNull(z.coerce.number().int()),
  rearOrSideLandings: coerceUndefinedToNull(z.coerce.number().int()),
  sensorEdgeBrandAndModel: coerceUndefinedToNull(z.string()),
  motorControllerType: coerceUndefinedToNull(z.enum(motorControllerOptions)),
  counterweightSafeties: coerceUndefinedToNull(z.boolean()),
  flexHoseHydro: coerceUndefinedToNull(z.boolean()),
  governerType: coerceUndefinedToNull(z.string()),
  systemType: coerceUndefinedToNull(
    z.enum(systemTypeValues as [string, ...string[]]),
  ),
  pressureSwitchHydro: coerceUndefinedToNull(z.boolean()),
  driveSheaveLiners: coerceUndefinedToNull(z.boolean()),
  batteryLowering: coerceUndefinedToNull(z.boolean()),
  brakeType: coerceUndefinedToNull(z.string()),
  controllerManufacturerAndModel: coerceUndefinedToNull(z.string()),
  controllerValveManufacturerAndModel: coerceUndefinedToNull(z.string()),
  suspensionMeans: coerceUndefinedToNull(z.enum(suspensionOptions)),
  suspensionMeansInstallYear: coerceUndefinedToNull(z.coerce.number().int()),
  doorRestrictorsType: coerceUndefinedToNull(z.string()),
  guideType: coerceUndefinedToNull(z.enum(guideOptions)),
  doorOperatorManufacturerAndType: coerceUndefinedToNull(z.string()),
  hoistwayAccess: coerceUndefinedToNull(z.boolean()),
  bufferType: coerceUndefinedToNull(z.string()),
  bufferSwitches: coerceUndefinedToNull(z.boolean()),
  compensationCablesChains: coerceUndefinedToNull(z.boolean()),
  overspeedValveHydro: coerceUndefinedToNull(z.boolean()),
  plungerGripperHydro: coerceUndefinedToNull(z.boolean()),
  notes: coerceUndefinedToNull(z.string()),
  certificatePicture: coerceUndefinedToNull(z.array(imageOptions)),
  fixturePictures: coerceUndefinedToNull(z.array(imageOptions)),
  machineRoomPictures: coerceUndefinedToNull(z.array(imageOptions)),
  controllerPicture: coerceUndefinedToNull(z.array(imageOptions)),
  additionalPhotos: coerceUndefinedToNull(z.array(imageOptions)),
  topOfCarPictures: coerceUndefinedToNull(z.array(imageOptions)),
  ropeDataTagPicture: coerceUndefinedToNull(z.array(imageOptions)),
  valvePictures: coerceUndefinedToNull(z.array(imageOptions)),
  pitPictures: coerceUndefinedToNull(z.array(imageOptions)),
  isThereAMachineRoom: coerceUndefinedToNull(z.boolean()),
  region: coerceUndefinedToNull(z.string()),
  contractType: coerceUndefinedToNull(z.string()),
  contractId: coerceUndefinedToNull(z.string()),
  enforcerDoorGibs: coerceUndefinedToNull(z.boolean()),
  LEDDownlightCeiling: coerceUndefinedToNull(z.boolean()),
  dateOfLastCAT1Testing: coerceUndefinedToNull(z.string()),
  dateOfLastCAT5Testing: coerceUndefinedToNull(z.string()),
  softStartElectronicStarter: coerceUndefinedToNull(z.boolean()),
  tankCooler: coerceUndefinedToNull(z.boolean()),
  tankHeater: coerceUndefinedToNull(z.boolean()),
  ropeGripper: coerceUndefinedToNull(z.boolean()),
  doorEquipmentUpgradeNeeded: coerceUndefinedToNull(z.boolean()),
  hallFixturesUpgradeNeeded: coerceUndefinedToNull(z.boolean()),
  positionIndicatorUpgradeNeeded: coerceUndefinedToNull(z.boolean()),
  DLM: coerceUndefinedToNull(z.boolean()),
  biDirectionalGovernor: coerceUndefinedToNull(z.boolean()),
  pumpUnitUpgradeNeeded: coerceUndefinedToNull(z.boolean()),
  controllerUpgradeNeeded: coerceUndefinedToNull(z.boolean()),
  machineUpgradeNeeded: coerceUndefinedToNull(z.boolean()),
  threeDSensorEdge: coerceUndefinedToNull(z.boolean()),
  cabUpgradeNeeded: coerceUndefinedToNull(z.boolean()),
  carTopHandrails: coerceUndefinedToNull(z.boolean()),
  singleBottomBulkheadJack: coerceUndefinedToNull(z.boolean()),
  retractablePitLadder: coerceUndefinedToNull(z.boolean()),
  retractableToeGuard: coerceUndefinedToNull(z.boolean()),
  scavengerPump: coerceUndefinedToNull(z.boolean()),
  capacityLbs: coerceUndefinedToNull(z.coerce.number().int()),
  speedFpm: coerceUndefinedToNull(z.coerce.number().int()),
});

export const ElevatorImageFields = z.object({
  certificatePicture: coerceUndefinedToNull(z.array(imageOptions)),
  fixturePictures: coerceUndefinedToNull(z.array(imageOptions)),
  machineRoomPictures: coerceUndefinedToNull(z.array(imageOptions)),
  controllerPicture: coerceUndefinedToNull(z.array(imageOptions)),
  additionalPhotos: coerceUndefinedToNull(z.array(imageOptions)),
  topOfCarPictures: coerceUndefinedToNull(z.array(imageOptions)),
  ropeDataTagPicture: coerceUndefinedToNull(z.array(imageOptions)),
  valvePictures: coerceUndefinedToNull(z.array(imageOptions)),
  pitPictures: coerceUndefinedToNull(z.array(imageOptions)),
});

export const ElevatorWithoutId = Elevator.omit({ id: true });

export const ElevatorCopyableFieldsForExtendedSurvey = Elevator.omit({
  id: true,
  name: true,
  unitNumber: true,
  ahj: true,
  ahjId: true,
  certificatePicture: true,
  fixturePictures: true,
  machineRoomPictures: true,
  controllerPicture: true,
  additionalPhotos: true,
  topOfCarPictures: true,
  ropeDataTagPicture: true,
  valvePictures: true,
  pitPictures: true,
});

export const ElevatorCopyableFieldsForBasicSurvey =
  ElevatorCopyableFieldsForExtendedSurvey.omit({
    doorRestrictorsType: true,
    sensorEdgeBrandAndModel: true,
    doorOperatorManufacturerAndType: true,
    governerType: true,
    brakeType: true,
    controllerValveManufacturerAndModel: true,
    bufferType: true,
    motorControllerType: true,
    counterweightSafeties: true,
    flexHoseHydro: true,
    pressureSwitchHydro: true,
    guideType: true,
    driveSheaveLiners: true,
    suspensionMeans: true,
    suspensionMeansInstallYear: true,
    batteryLowering: true,
    bufferSwitches: true,
    compensationCablesChains: true,
    overspeedValveHydro: true,
    plungerGripperHydro: true,
  });

export const ExploreElevator = z
  .object({
    site: coerceUndefinedToNull(z.string()),
  })
  .merge(Elevator);

export const ExportElevator = ExploreElevator.omit({
  id: true,
  propertyId: true,
  servmanId: true,
  certificatePicture: true,
  fixturePictures: true,
  machineRoomPictures: true,
  controllerPicture: true,
  additionalPhotos: true,
  topOfCarPictures: true,
  ropeDataTagPicture: true,
  valvePictures: true,
  pitPictures: true,
  contractId: true,
});

export type Elevator = z.infer<typeof Elevator>;
export type ElevatorWithoutId = z.infer<typeof ElevatorWithoutId>;
export type ElevatorCopyableFieldsForExtendedSurvey = z.infer<
  typeof ElevatorCopyableFieldsForExtendedSurvey
>;
export type ElevatorCopyableFieldsForBasicSurvey = z.infer<
  typeof ElevatorCopyableFieldsForBasicSurvey
>;

export type ElevatorInput = z.input<typeof Elevator>;
export type ElevatorImage = z.input<typeof imageOptions>;
export type ExportElevator = z.infer<typeof ExportElevator>;
export type ExploreElevator = z.infer<typeof ExploreElevator>;
export type ElevatorImageFields = z.infer<typeof ElevatorImageFields>;
