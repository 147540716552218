import { z } from "zod";
import { WorkDay } from "../../views/copilot/interfaces";

export const elevatorStatusOrder = [
  "open-trouble-calls",
  "overdue",
  "needs-visit",
  "trouble-calls",
];

export const ElevatorStatusIndicator = z.enum([
  "needs-visit",
  "overdue",
  "trouble-calls",
  "open-trouble-calls",
]);
export type ElevatorStatusIndicator = z.infer<typeof ElevatorStatusIndicator>;

export const ElevatorStatusIndicatorMap: Record<
  ElevatorStatusIndicator,
  string
> = {
  "open-trouble-calls": "OPEN TROUBLE CALLS",
  "needs-visit": "NEEDS VISIT",
  overdue: "VISIT OVERDUE",
  "trouble-calls": "TROUBLE CALLS",
};

export const getElevatorsCount = (workDay: WorkDay) => {
  return workDay.properties.flatMap((property) => property.elevators).length;
};

export const CopilotElevatorStats = z.object({
  tripsSinceLastVisit: z
    .number()
    .int()
    .nonnegative("Trips must be non-negative")
    .nullable(),
  troubleCallsSinceLastVisit: z
    .number()
    .int()
    .nonnegative("Trouble calls must be non-negative"),
  troubleCallsLast90Days: z
    .number()
    .int()
    .nonnegative("Trouble calls last 90 days must be non-negative"),
  maintenanceHoursRequired: z
    .number()
    .nonnegative("Maintenance hours required must be non-negative"),
  maintenanceHoursReceived: z
    .number()
    .nonnegative("Maintenance hours received must be non-negative"),
  visitsRemaining: z
    .number()
    .int()
    .nonnegative("Visits remaining must be non-negative")
    .nullable(),
  lastMaintenanceDate: z.string().nullable(),
  contractYearStart: z.string().nullable(),
  contractName: z.string().nullable(),
  indicators: z.array(ElevatorStatusIndicator),
  noSensorBox: z.boolean().nullable(),
});

export const hasOpenTroubleCalls = (elevator: CopilotElevator) =>
  elevator.stats && elevator.stats.indicators?.includes("open-trouble-calls");

export const isOverdue = (elevator: CopilotElevator) =>
  elevator.stats && elevator.stats.indicators?.includes("overdue");

export const needsVisit = (elevator: CopilotElevator) =>
  elevator.stats && elevator.stats.indicators?.includes("needs-visit");

export const hasTroubleCalls = (elevator: CopilotElevator) =>
  elevator.stats && elevator.stats.indicators?.includes("trouble-calls");

export const CopilotElevator = z.object({
  id: z.string(),
  name: z.string(),
  propertyId: z.string(),
  ahjId: z.string(),
  stats: CopilotElevatorStats.optional(),
  // TODO: Eventually make this a date that is converted to a Firestore Timestamp upon save
  statsLastUpdated: z.string().datetime().nullable(),
  mechanicId: z.string(),
  routeName: z.string(),
});

export const CopilotElevatorWithoutId = CopilotElevator.omit({ id: true });

export type CopilotElevatorWithoutId = z.infer<typeof CopilotElevatorWithoutId>;
export type CopilotElevator = z.infer<typeof CopilotElevator>;
