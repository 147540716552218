import { Controller, useFormContext } from "react-hook-form";
import { Select } from "../../components/Select";
import { Textarea } from "../../components/Textarea";
import { BonusBanner } from "../../components/BonusBanner";
import type { SurveyFormVm } from "./SurveyForm";
import { ErrorMessage } from "@hookform/error-message";
import { LaiErrorMessage } from "../../components/ErrorMessage";
import { useEffect, useState, useMemo } from "react";
import { GroupedCheckboxes } from "../../components/GroupedCheckboxes";
import {
  recommendationTypeOptions,
  recommendationDefaultOption,
  recommendationTypeValueFormatter,
  getRecommendationOptions,
  RecommendationType,
} from "../../models/opportunity";
import { SystemTypeOption } from "../../api/interface";

const RecommendationsTab = () => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useFormContext<SurveyFormVm>();
  const [recommendationType, setRecommendationType] =
    useState<RecommendationType>(recommendationDefaultOption);

  const selectedRecommendationOptions = watch(
    "recommendation.recommendationOptions",
  );
  // const recommendations = watch("recommendation");

  useEffect(() => {
    if (recommendationType === recommendationDefaultOption) {
      setValue("recommendation", null);
      clearErrors("recommendation");
    } else {
      setValue("recommendation", {
        type: recommendationType,
        opportunity: "",
        recommendationOptions: [],
      });
    }
  }, [recommendationType, setValue, clearErrors]);

  const systemType = watch("systemType");

  const recommendationOptions = useMemo(() => {
    return getRecommendationOptions(systemType as SystemTypeOption);
  }, [systemType]);

  return (
    <div className="grid grid-cols-1 gap-4">
      <BonusBanner />
      <Select
        name="recommendation.recommendationType"
        label="Recommendation Type"
        value={recommendationType}
        options={recommendationTypeOptions}
        valueFormatter={recommendationTypeValueFormatter}
        onChange={(value) => {
          if (typeof value !== "string") {
            // This should not happen but we need to appease typescript
            return;
          }
          setRecommendationType(value);
        }}
      />
      {recommendationType === recommendationTypeOptions[1] ? (
        <Controller
          control={control}
          name="recommendation.recommendationOptions"
          render={({ field: { ref: _, onChange, ...fieldProps } }) => (
            <GroupedCheckboxes
              options={recommendationOptions}
              label="Recommendation Options"
              selectedOptions={selectedRecommendationOptions || []}
              onCheck={(newValue) => onChange(newValue)}
              {...fieldProps}
            />
          )}
        />
      ) : null}
      {recommendationType !== recommendationDefaultOption ? (
        <Controller
          control={control}
          name="recommendation.opportunity"
          render={({ field: { ref: _, ...fieldProps } }) => (
            <Textarea
              helperText="Please add any helpful information about the building, parking, access to building, where to find building contact, access to unit, keys needed, machine room location and access, etc."
              label="Recommendation Description"
              disabled={false}
              {...fieldProps}
            />
          )}
        />
      ) : null}
      <ErrorMessage
        as={LaiErrorMessage}
        errors={errors}
        name="recommendation.opportunity"
      />
    </div>
  );
};

export default RecommendationsTab;
