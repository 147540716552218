import {
  Elevator,
  ElevatorCopyableFieldsForBasicSurvey,
  ElevatorCopyableFieldsForExtendedSurvey,
  isHydraulic,
  isTraction,
} from "../models/elevator";
import { SystemTypeOption } from "../api/interface";
import { SurveyFormValidation, SurveyFormVm } from "../views/survey/SurveyForm";
import { WorkDay, CopilotProperty } from "../views/copilot/interfaces";
import {
  hasTroubleCalls,
  needsVisit,
  isOverdue,
  hasOpenTroubleCalls,
  CopilotElevator,
} from "../models/copilot";

type SurveyStats = {
  identificationCount: number;
  identificationCompletedCount: number;
  generalCount: number;
  generalCompletedCount: number;
  technicalCount: number;
  technicalCompletedCount: number;
  totalCount: number;
  totalCompletedCount: number;
  totalCountLabel: string;
};

export const camelCaseToTitleCase = (str: string): string => {
  const spacedStr = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  const titleCaseStr = spacedStr.replace(/\b\w/g, (char) => char.toUpperCase());
  return titleCaseStr;
};

const checkIfFieldIsCompleted = (field: unknown): boolean => {
  if (field === null || field === undefined) {
    return false;
  }

  if (typeof field === "string") {
    return field.length > 0;
  }

  if (Array.isArray(field)) {
    return field.length > 0;
  }

  return true;
};

export const calculateCompleted = (
  elevator: SurveyFormVm,
  mode: "basic" | "extended",
): SurveyStats => {
  const {
    name,
    unitNumber,
    ahj,
    ahjId,
    certificatePicture,
    systemType,
    condition,
    designQuality,
    facilityType,
    typeOfUse,
    traffic,
    environment,
    doorType,
    landings,
    frontLandings,
    rearOrSideLandings,
    fixturePictures,
    hoistwayAccess,
    fireService,
    machineRoomPictures,
    machineInstallYear,
    technology,
    isThereAMachineRoom,
    controllerPicture,
    controllerManufacturerAndModel,
    controllerInstallYear,
    notes,
    additionalPhotos,
    sensorEdgeBrandAndModel,
    doorRestrictorsType,
    doorOperatorManufacturerAndType,
    topOfCarPictures,
    motorControllerType,
    counterweightSafeties,
    flexHoseHydro,
    governerType,
    pressureSwitchHydro,
    guideType,
    driveSheaveLiners,
    suspensionMeans,
    suspensionMeansInstallYear,
    ropeDataTagPicture,
    brakeType,
    batteryLowering,
    controllerValveManufacturerAndModel,
    valvePictures,
    bufferType,
    bufferSwitches,
    compensationCablesChains,
    overspeedValveHydro,
    plungerGripperHydro,
    pitPictures,
    enforcerDoorGibs,
    LEDDownlightCeiling,
    dateOfLastCAT1Testing,
    dateOfLastCAT5Testing,
    softStartElectronicStarter,
    tankCooler,
    tankHeater,
    ropeGripper,
    doorEquipmentUpgradeNeeded,
    hallFixturesUpgradeNeeded,
    positionIndicatorUpgradeNeeded,
    DLM,
    biDirectionalGovernor,
    pumpUnitUpgradeNeeded,
    controllerUpgradeNeeded,
    machineUpgradeNeeded,
    threeDSensorEdge,
    cabUpgradeNeeded,
    carTopHandrails,
    singleBottomBulkheadJack,
    retractablePitLadder,
    retractableToeGuard,
    scavengerPump,
    capacityLbs,
    speedFpm,
  } = elevator;

  const isUnitHydraulic = isHydraulic(systemType as SystemTypeOption);
  const isUnitTraction = isTraction(systemType as SystemTypeOption);
  const generalHydroFields = [
    softStartElectronicStarter,
    tankCooler,
    tankHeater,
  ];
  const generalHydroCount = generalHydroFields.length;
  const generalTractionFields = [ropeGripper];
  const generalTractionCount = generalTractionFields.length;
  const technicalHydroFields = [
    pressureSwitchHydro,
    flexHoseHydro,
    pumpUnitUpgradeNeeded,
    overspeedValveHydro,
    plungerGripperHydro,
    singleBottomBulkheadJack,
  ];
  const technicalHydroCount = technicalHydroFields.length;
  const technicalTractionFields = [biDirectionalGovernor, machineUpgradeNeeded];
  const technicalTractionCount = technicalTractionFields.length;

  const identificationFields = [
    name,
    unitNumber,
    ahj,
    ahjId,
    certificatePicture,
  ];

  const generalFields = [
    isThereAMachineRoom,
    systemType,
    condition,
    facilityType,
    traffic,
    doorType,
    landings,
    frontLandings,
    rearOrSideLandings,
    fixturePictures,
    hoistwayAccess,
    fireService,
    machineRoomPictures,
    machineInstallYear,
    technology,
    controllerPicture,
    controllerManufacturerAndModel,
    controllerInstallYear,
    notes,
    additionalPhotos,
    enforcerDoorGibs,
    LEDDownlightCeiling,
    dateOfLastCAT1Testing,
    dateOfLastCAT5Testing,
    capacityLbs,
    speedFpm,
  ];

  if (isUnitHydraulic) {
    generalFields.push(...generalHydroFields);
  } else if (isUnitTraction) {
    generalFields.push(...generalTractionFields);
  }

  const technicalFields =
    mode === "extended"
      ? [
          sensorEdgeBrandAndModel,
          doorRestrictorsType,
          doorOperatorManufacturerAndType,
          topOfCarPictures,
          motorControllerType,
          counterweightSafeties,
          governerType,
          guideType,
          driveSheaveLiners,
          suspensionMeans,
          suspensionMeansInstallYear,
          ropeDataTagPicture,
          brakeType,
          batteryLowering,
          controllerValveManufacturerAndModel,
          valvePictures,
          bufferType,
          bufferSwitches,
          compensationCablesChains,
          pitPictures,
          typeOfUse,
          designQuality,
          environment,
          doorEquipmentUpgradeNeeded,
          hallFixturesUpgradeNeeded,
          positionIndicatorUpgradeNeeded,
          DLM,
          controllerUpgradeNeeded,
          threeDSensorEdge,
          cabUpgradeNeeded,
          carTopHandrails,
          retractablePitLadder,
          retractableToeGuard,
          scavengerPump,
        ]
      : [];

  if (isUnitHydraulic) {
    technicalFields.push(...technicalHydroFields);
  } else if (isUnitTraction) {
    technicalFields.push(...technicalTractionFields);
  }

  const identificationCompletedCount = identificationFields.filter((field) =>
    checkIfFieldIsCompleted(field),
  ).length;
  const generalCompletedCount = generalFields.filter((field) =>
    checkIfFieldIsCompleted(field),
  ).length;
  const technicalCompletedCount = technicalFields.filter((field) =>
    checkIfFieldIsCompleted(field),
  ).length;

  const totalCount =
    identificationFields.length + generalFields.length + technicalFields.length;

  const maxGeneralCount =
    totalCount + Math.max(generalHydroCount, generalTractionCount);
  const maxTechnicalCount =
    totalCount + Math.max(technicalHydroCount, technicalTractionCount);

  const totalCountLabel =
    mode === "basic"
      ? `${totalCount} - ${maxGeneralCount}`
      : `${totalCount} - ${maxTechnicalCount}`;

  const totalCompletedCount =
    identificationCompletedCount +
    generalCompletedCount +
    technicalCompletedCount;

  return {
    identificationCount: identificationFields.length,
    identificationCompletedCount,
    generalCount: generalFields.length,
    generalCompletedCount,
    technicalCount: technicalFields.length,
    technicalCompletedCount,
    totalCount,
    totalCountLabel,
    totalCompletedCount,
  };
};

type CopyElevatorOpts = {
  from: Elevator;
  to: Elevator;
  mode?: "basic" | "extended";
};

export const cloneElevator = ({
  from,
  to,
  mode = "basic",
}: CopyElevatorOpts): Elevator => {
  const fieldsToCopy =
    mode === "basic"
      ? ElevatorCopyableFieldsForBasicSurvey.parse(from)
      : ElevatorCopyableFieldsForExtendedSurvey.parse(from);

  return {
    ...to,
    ...fieldsToCopy,
  };
};

export const downloadBase64File = (base64Data: string, filename: string) => {
  // Decode the base64 string to binary data
  const binaryString = window.atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Convert binary data to a Blob
  const blob = new Blob([bytes], { type: "application/octet-stream" });

  // Create a URL for the Blob
  const blobURL = URL.createObjectURL(blob);

  // Create an anchor element and trigger the download
  const link = document.createElement("a");
  link.href = blobURL;
  link.download = filename;
  document.body.appendChild(link); // Required for Firefox
  link.click();
  document.body.removeChild(link); // Clean up

  // Optionally, revoke the blobURL to free up memory
  URL.revokeObjectURL(blobURL);
};

export const filterWorkDays = (workDays: WorkDay[], searchQuery: string) =>
  workDays?.filter(
    (workDay) =>
      workDay.workDayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      workDay.properties.some((property) =>
        property.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ) ||
      workDay.properties.some((property) =>
        property.elevators.some((elevator) =>
          elevator.name.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      ),
  );

const getElevatorS = (item: WorkDay | CopilotProperty | CopilotElevator) => {
  return "properties" in item
    ? item.properties.flatMap((p) => p.elevators)
    : "elevators" in item
      ? item.elevators
      : item;
};

export const sortByIndicators = (
  a: WorkDay | CopilotProperty | CopilotElevator,
  b: WorkDay | CopilotProperty | CopilotElevator,
): number => {
  const aEl = getElevatorS(a); // CopilotElevator | CopilotElevator[]
  const bEl = getElevatorS(b); // CopilotElevator | CopilotElevator[]
  const aElObject = !Array.isArray(aEl);
  const bElObject = !Array.isArray(bEl);

  const aHasOpenTroubleCalls = aElObject
    ? hasOpenTroubleCalls(aEl)
    : aEl.some(hasOpenTroubleCalls);
  const bHasOpenTroubleCalls = bElObject
    ? hasOpenTroubleCalls(bEl)
    : bEl.some(hasOpenTroubleCalls);
  if (aHasOpenTroubleCalls && !bHasOpenTroubleCalls) return -1;
  if (!aHasOpenTroubleCalls && bHasOpenTroubleCalls) return 1;

  const aIsOverdue = aElObject ? isOverdue(aEl) : aEl.some(isOverdue);
  const bIsOverdue = bElObject ? isOverdue(bEl) : bEl.some(isOverdue);
  if (aIsOverdue && !bIsOverdue) return -1;
  if (!aIsOverdue && bIsOverdue) return 1;

  const aNeedsVisit = aElObject
    ? needsVisit(aEl)
    : aEl.some((e) => needsVisit(e) || hasTroubleCalls(e));
  const bNeedsVisit = bElObject
    ? needsVisit(bEl)
    : bEl.some((e) => needsVisit(e) || hasTroubleCalls(e));
  if (aNeedsVisit && !bNeedsVisit) return -1;
  if (!aNeedsVisit && bNeedsVisit) return 1;

  return 0;
};

export const isElevatorValid = (elevator: Elevator) => {
  try {
    SurveyFormValidation.parse(elevator);
    return true;
  } catch (error) {
    return false;
  }
};
